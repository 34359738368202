<template>
    <div class="action-contact-request">
        <div class="title" v-html="title" />
        <div class="body">
            <div v-html="body" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionContactRequest',
    props: ['message'],
    computed: {
        user() {
            return this.$nameOrNick(this.$store.getters.chat.user)
        },
        contactUser() {
            return this.$mustParse(this.message.content).name
        },
        title() {
            return this.$translate('ACTION_CONTACT_REQUEST_TITLE').replace(/%s/, this.contactUser)
        },
        body() {
            return this.$translate('ACTION_CONTACT_REQUEST_BODY').replace(/%s/, this.user)
        },
    },
}
</script>
